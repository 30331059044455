import React, { useEffect, useState } from 'react';
import { FilterIcon, Filters, StyledFilterBarSelect, StyledFilterBarSelectPaginate } from 'pages/shared/shared.style';
import {
  ApprovalStatus,
  ApprovalStatusLabel,
  ApprovalStatusWithLabel,
  GenericEntity,
  OrderDirection,
} from 'utils/types';
import { OfferTemplates, OfferApprovalStatuses } from 'utils/types/offers';
import { useSelector } from 'react-redux';
import { useUrlFilters } from 'hooks/use-url-filters';
import { SearchTextField } from 'components/shared/searchTextField/SearchTextField';
import { store } from 'app/store';
import { BaseOfferRec, OfferBank, doeOffersPage, vceOffersPage } from 'app/genericSlices/offers';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import { MarketConfigurationGuard } from 'components/zoneGuard/MarketConfigurationGuard';
import { MarketConfigurationKey } from 'pages/configurations/Configurations.consts';
import locationSetsGqls from 'pages/settings/locationSets/LocationSets.gqls';
import { useQuery } from '@apollo/client';
import { LocationSet } from 'utils/types/locations';
import { LocationSetsFilters } from 'pages/settings/locationSets/LocationSets.consts';
import { usePersistCaretPosition } from 'hooks/use-persist-caret-position';
import { OfferFilters, OfferSource } from '../../Offers.const';
import { FetchPolicies } from 'utils/types/common';
import { useBaseOfferRecsQuery } from 'hooks/base-offer-recs';
import { useOfferBanksQuery } from 'hooks/offer-banks';
import { marketConfig } from 'app/slices/config';

export const OfferFilterBar = ({ offerSource }: { offerSource: OfferSource }) => {
  const { load: loadTags, tags } = useTagsQuery([TagsValidEntities.Offer]);

  const offersPage = offerSource === OfferSource.VCE ? vceOffersPage : doeOffersPage;
  const urlFilters = useUrlFilters((params: any) => {
    store.dispatch(
      offersPage.actions.setFilters(
        Object.keys(params).reduce(
          (res: any, key: any) => ({ ...res, [key]: Array.isArray(params[key]) ? params[key] : [params[key]] }),
          {},
        ),
      ),
    );
  });

  const { filters } = useSelector(offersPage.offersState);
  const [caretPosition, setCaretPosition] = usePersistCaretPosition(filters[OfferFilters.SearchQuery]);

  const { loadOptions: loadOfferBanks } = useOfferBanksQuery(true);
  const { loadOptions: loadOfferGroup } = useBaseOfferRecsQuery(true, filters?.[OfferFilters.OfferBank]);
  const [offerBankOpen, setOfferBankOpen] = useState(false);
  const [offerGroupKey, setOfferGroupKey] = useState(false);
  const [offerGroupOpen, setOfferGroupOpen] = useState(false);
  useEffect(() => {
    setOfferGroupKey((prev) => !prev);
  }, [offerGroupOpen]);

  const filteredStatuses =
    offerSource === OfferSource.DOE ? [ApprovalStatus.Approved, ApprovalStatus.Archived] : OfferApprovalStatuses;

  const { data: zonesData } = useQuery<{ getLocationSets: GenericEntity<LocationSet> }>(
    locationSetsGqls.queries.getZones,
    {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      skip: offerSource === OfferSource.DOE,
      notifyOnNetworkStatusChange: true,
      variables: {
        data: {
          filters: { [LocationSetsFilters.CustomSets]: false },
          order: { name: OrderDirection.ASC },
        },
      },
    },
  );

  useEffect(() => {
    if (!Object.keys(urlFilters.params).length) {
      urlFilters.filterMulti(filters);
    }
    loadTags();
  }, []);

  const { config } = useSelector(marketConfig);
  const { enableDigitalDownload } = config;
  const filteredOfferTemplates = Object.values(OfferTemplates).filter(
    (template) => (offerSource == OfferSource.VCE && enableDigitalDownload) || template.id !== '14',
  );
  return (
    <Filters>
      <FilterIcon name="filter" />
      <StyledFilterBarSelect
        placeholder="Select"
        name="template"
        label="Template"
        multiple
        items={Object.values(filteredOfferTemplates) as any[]}
        onChange={(selectedItems: any) => {
          urlFilters.filter(
            OfferFilters.TemplateType,
            Object.values(selectedItems).map((i: any) => i.id),
          );
        }}
        initialSelectedItems={filters[OfferFilters.TemplateType]}
        reset
        maxItems={1}
        selectWidth={160}
      />
      <StyledFilterBarSelect
        placeholder="Select"
        label="Status"
        name="status"
        multiple
        items={
          filteredStatuses.map((status) => {
            return {
              id: status,
              name: ApprovalStatusLabel[status as ApprovalStatusWithLabel],
            };
          }) as any[]
        }
        onChange={(selectedItems: any) => {
          urlFilters.filter(
            OfferFilters.Status,
            Object.values(selectedItems).map((i: any) => i.id),
          );
        }}
        initialSelectedItems={filters[OfferFilters.Status]}
        reset
        maxItems={1}
        selectWidth={160}
      />
      <StyledFilterBarSelect
        key={`${Boolean(tags.length)}`}
        placeholder="Select"
        label="Tags"
        name="tags"
        multiple
        maxItems={1}
        items={tags}
        onChange={(selectedItems: any) => {
          urlFilters.filter(
            OfferFilters.Tags,
            Object.values(selectedItems).map((i: any) => i.id),
          );
        }}
        initialSelectedItems={tags?.length ? filters[OfferFilters.Tags] : []}
        reset
        withAmount
        withSearch
        selectWidth={160}
      />
      {offerSource === OfferSource.VCE && (
        <MarketConfigurationGuard
          configurations={[{ configKey: MarketConfigurationKey.EnableManagementByZone, value: true }]}
        >
          <StyledFilterBarSelect
            key={`${JSON.stringify(zonesData?.getLocationSets?.items)}`}
            placeholder="Select"
            name="zone"
            label="Zone"
            multiple
            items={zonesData?.getLocationSets?.items}
            onChange={(selectedItems: any) => {
              urlFilters.filter(
                OfferFilters.Zone,
                Object.values(selectedItems).map((i: any) => i.id),
              );
            }}
            initialSelectedItems={zonesData?.getLocationSets?.total ? filters[OfferFilters.Zone] : []}
            reset
            withSearch
            maxItems={1}
            selectWidth={160}
          />
        </MarketConfigurationGuard>
      )}
      {offerSource === OfferSource.DOE && (
        <>
          <StyledFilterBarSelectPaginate
            placeholder="Select"
            name="offerBank"
            label="Offer Bank"
            multiple
            onChange={(selectedItems: { id: string; name: string }[]) => {
              urlFilters.filter(
                OfferFilters.OfferBank,
                selectedItems.map((i) => i.id),
              );
            }}
            initialSelectedItems={filters?.[OfferFilters.OfferBank]?.length > 0 ? filters[OfferFilters.OfferBank] : []}
            reset
            maxItems={1}
            isOpen={offerBankOpen}
            setIsOpen={setOfferBankOpen}
            loadOptions={loadOfferBanks}
            selectWidth={160}
          />
          <StyledFilterBarSelectPaginate
            key={`offerFilter_${offerGroupKey}`}
            placeholder="Select"
            name="offerGroup"
            label="Offer Group"
            multiple
            onChange={(selectedItems: BaseOfferRec[]) => {
              urlFilters.filter(
                OfferFilters.OfferGroup,
                selectedItems.map((i) => i.id),
              );
            }}
            disabled={!(filters[OfferFilters.OfferBank] && filters[OfferFilters.OfferBank].length > 0)}
            loadOptions={loadOfferGroup}
            initialSelectedItems={
              filters?.[OfferFilters.OfferGroup]?.length > 0 ? filters[OfferFilters.OfferGroup] : []
            }
            reset
            isOpen={offerGroupOpen}
            setIsOpen={setOfferGroupOpen}
            maxItems={1}
            selectWidth={160}
          />
        </>
      )}
      <SearchTextField
        key={`${JSON.stringify(filters[OfferFilters.SearchQuery])}_SearchQuery`}
        name="offers-search"
        caretPosition={caretPosition}
        value={filters[OfferFilters.SearchQuery] ?? ''}
        onChange={(e) => {
          urlFilters.filter(OfferFilters.SearchQuery, e.target.value);
          setCaretPosition(e);
        }}
      />
    </Filters>
  );
};
