import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ApprovalStatus, FormMode } from 'utils/types';
import { Language, LanguageRecord } from 'utils/languages';

import {
  BaseOffer,
  DiscountCondition,
  OfferActions,
  OfferActionsList,
  OfferTemplates,
  PointOfDistributionRecord,
} from 'utils/types/offers';
import { Term } from 'utils/types/terms';
import { marketConfig } from 'app/slices/config';
import { useSelector } from 'react-redux';
import { ButtonContained, ButtonText } from 'components/shared/button';
import { convertEnumToObjectArray } from 'utils/mapping';
import ReactTooltip from 'react-tooltip';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { store } from 'app/store';
import { closeModal, Modals, openModal, setData } from 'app/slices/modals';
import { createNewOffer, createOfferTag, setOfferApprovalStatus } from 'utils/api/offers';
import { NoImageText } from 'pages/offers/offerManagement/Offers.style';
import { cloneDeep, forOwn, get } from 'lodash';
import { TermConditionProps } from 'pages/settings/termsConditions/TermsConditions.consts';
import { hasValue } from 'utils/text';
import { isInArray } from 'utils/array';
import {
  StyledApproveButton,
  StyledFormSection,
  StyledRejectButton,
  StyledSectionTitle,
} from 'pages/shared/shared.style';
import { getOfferStatus, viewImageClicked } from 'utils/offer';
import { hideTooltip } from 'utils/tooltip';
import { EntityApproveButton } from 'pages/shared/entityApproveButton/EntityApproveButton';
import { EntityType } from 'pages/shared/entityApproveButton/EntityApproveButton.consts';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { SetItemsSelectionFormState } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import CheckboxGroup from 'components/shared/checkboxGroup/CheckboxGroup';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import {
  checkForEmptyData,
  convertToGenericSet,
} from 'pages/offers/offerManagement/components/offerForm/components/templates/shared/ProductsActionCondition.utils';
import useFeatureFlag from 'hooks/use-feature-flag';
import { Feature } from 'utils/types/features';
import { termsConditionsSelection } from 'app/genericSlices/termsConditions';
import { FeatureGuard } from 'components/featureGuard/FeatureGuard';
import {
  ImageContainer,
  OfferFormAdditionalDetails,
  OfferFormContainer,
  OfferFormContent,
  OfferFormContentContainer,
  OfferFormFooter,
  OfferFormImage,
  OfferFormImageContainer,
  StyledButtonDropdown,
  StyledButtonText,
  StyledError,
  StyledImageButtonText,
  StyledInfoTooltip,
  StyledModal,
  StyledSelectbox,
  StyledTabStrip,
  StyledTermsAndConditionsTextArea,
  StyledTextArea,
  StyledTextField,
  StyledViewImage,
  TagsSelectbox,
  TemplateSelectbox,
} from './OfferForm.style';
import { OfferFormProps } from './OfferForm.const';
import { renderTemplate } from './components/templates';
import { useOfferFromRedux } from './components/hooks/useOfferFromRedux';
import { OfferStatusLabel } from './components/offerStatusLabel/OfferStatusLabel';
import {
  archiveOffer,
  onOfferArchive,
  onOfferEdit,
  onOfferReject,
  onOfferViewImpact,
} from '../offerActions/OfferActions';
import { PriceOffValidationError } from './components/priceOffValidationError/PriceOffValidationError';
import { ValidationMessages } from 'utils/types/common';
import { inProcessErrorMessage } from 'pages/shared/shared.consts';
import { OfferSource } from '../../Offers.const';
import { OfferTemplatesConfig, convertStringToIntArray } from 'pages/configurations/config.util';
import { convertUtcDateToTimezoneDate, getMarketDatetime } from 'utils/date';

const OfferForm = ({
  offer,
  defaultLanguage,
  mode,
  onClose,
  viewOnly = false,
  offerFormTags,
  offerSelectedLanguage,
  offerSource,
}: OfferFormProps) => {
  const {
    offer: persistentOffer,
    mode: persistentMode,
    selectedLanguage: persistentLanguage,
    offerSource: persistentOfferSource,
  }: { offer: BaseOffer; mode: string; selectedLanguage: string; offerSource: string } = useOfferFromRedux(
    cloneDeep(offer),
  );
  let loadTags: any;
  let tags: any[];
  if (!offerFormTags) {
    offerFormTags = useTagsQuery([TagsValidEntities.Offer]);
    loadTags = offerFormTags.load;
    tags = offerFormTags.tags;
  } else {
    loadTags = offerFormTags.load;
    tags = offerFormTags.tags;
  }

  const compareOffers = () => {
    if (offer) {
      if (offer !== persistentOffer) {
        return false;
      }
    }
    return true;
  };
  const [voucherDetails, setVoucherDetails] = useState({
    name: '',
    startDate: '',
    endDate: '',
  });

  const { config, languages } = useSelector(marketConfig);
  const draftCampaignOfferEnhancement = useFeatureFlag(Feature.DraftCampaignOfferEnhancement);
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: compareOffers() ? (persistentOffer as any) : (offer as any),
  });
  const [selectedTemplate, setSelectedTemplate] = useState(formMethods.getValues('templateType'));

  const [selectedLanguage, setSelectedLanguage] = useState(
    persistentLanguage ?? defaultLanguage ?? config.primaryLanguage,
  );
  const [tempTranslationsMap, setTempTranslationsMap] = useState<{ [key: string]: string }>({});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalMode, setModalMode] = useState(mode ?? persistentMode);
  const [modalOfferSource] = useState(offerSource ?? persistentOfferSource);
  const isInitialMount = useRef(true);
  const terms = useSelector((state: any) => state.terms);

  let termsConditions: any;

  if (modalOfferSource === OfferSource.DOE) {
    const selectedTemplateTermsFromConfig = convertStringToIntArray(
      get(config, OfferTemplatesConfig[selectedTemplate].name),
    );
    const selectedTemplateTermsFromConfigSet = new Set(selectedTemplateTermsFromConfig);
    termsConditions =
      selectedTemplate && terms.termsConditionsSelection.termsConditions && selectedTemplateTermsFromConfig
        ? terms.termsConditionsSelection.termsConditions.filter((t: Term) => {
            return selectedTemplateTermsFromConfigSet.has(Number(t.id));
          })
        : [];
  } else {
    termsConditions =
      selectedTemplate && terms.termsConditionsSelection.termsConditions
        ? terms.termsConditionsSelection.termsConditions
            .filter((t: Term) => t.template.includes(selectedTemplate))
            .map((t: Term) => ({ ...t, id: Number(t.id) }))
        : [];
  }

  const requiredValidation = useMemo(() => ({ required: true }), []);
  const offerStatus = getOfferStatus(persistentOffer);
  const shouldDisplayInfoIcon =
    (persistentOffer?.versions && persistentOffer?.versions[0].term?.originalTermId) ||
    (isInArray([FormMode.New, FormMode.Edit, FormMode.Duplicate], modalMode) &&
      formMethods.formState.dirtyFields.term_content);
  let disabledGroupIdInput =
    modalMode === FormMode.View ||
    (persistentOffer.segmentId && ![ApprovalStatus.Draft, ApprovalStatus.PendingApproval].includes(offerStatus));

  const setTranslations = (tc: any) => {
    if (!tc) {
      return;
    }
    const content = tc.translationsMap;
    if (content) {
      formMethods.setValue(
        `term_content`,
        Object.keys(content).reduce((m, key) => ({ ...m, [key]: content[key].content }), {}),
        { shouldValidate: true },
      );
    }
    formMethods.setValue(`term_name`, tc.name);
    formMethods.setValue(`versions.0.term`, tc.originalTermId ?? tc.id);
  };

  let hasExpireVoucherGroupError = false;
  if (
    voucherDetails &&
    getMarketDatetime(config.endTimezone).getTime() >
      convertUtcDateToTimezoneDate(voucherDetails.endDate, config.endTimezone).getTime()
  ) {
    hasExpireVoucherGroupError = true;
  }

  let hasProductsOrVoucherGroup = false;
  const checkProducts = (productsObject: any): boolean => {
    let res = false;
    forOwn(productsObject, (value, key) => {
      if (value?.length > 0) {
        res = true;
      }
    });
    return res;
  };
  // checking offer has product set or not
  if (modalMode === FormMode.New || modalMode === FormMode.Duplicate) {
    hasProductsOrVoucherGroup = true;
    disabledGroupIdInput = false;
  } else if (modalMode === FormMode.Edit || offerStatus === 'draft') {
    const templateValues = get(formMethods.getValues(), 'versions[0].templateValues');
    switch (selectedTemplate) {
      case '1':
      case '11':
        hasProductsOrVoucherGroup =
          checkProducts(templateValues.buyProducts) && checkProducts(templateValues.getProducts);
        break;
      case '2':
        hasProductsOrVoucherGroup = checkProducts(templateValues.products);
        break;
      case '3':
        hasProductsOrVoucherGroup = checkProducts(templateValues.buyProducts);
        break;
      case '4':
        hasProductsOrVoucherGroup = checkProducts(templateValues.substitutes) && templateValues.product !== undefined;
        break;
      case '5':
        if (templateValues.discountCondition === DiscountCondition.WithPurchaseOf) {
          hasProductsOrVoucherGroup = checkProducts(templateValues.conditionProducts);
        } else {
          hasProductsOrVoucherGroup = true;
        }
        break;
      case '7':
        hasProductsOrVoucherGroup =
          checkProducts(templateValues.buyProductsA) &&
          checkProducts(templateValues.buyProductsB) &&
          checkProducts(templateValues.getProducts);
        break;
      case '8':
        hasProductsOrVoucherGroup =
          checkProducts(templateValues.buyProducts) &&
          checkProducts(templateValues.getProductsA) &&
          checkProducts(templateValues.getProductsB);
        break;
      case '10':
        hasProductsOrVoucherGroup = checkProducts(templateValues.products);
        break;
      case '13':
        let count = 0;
        templateValues?.offerTemplates?.forEach((offerTemplate: any) => {
          const res = checkProducts(offerTemplate.buyProducts);
          if (res) {
            count++;
          }
        });
        hasProductsOrVoucherGroup = count === templateValues?.offerTemplates?.length;
        break;
      case '14':
        hasProductsOrVoucherGroup = templateValues.nonFoodDiscountRewards !== undefined ? true : false;
        break;
    }
  }

  if (
    (modalMode === FormMode.Duplicate || modalMode === FormMode.Edit || modalMode === FormMode.View) &&
    (selectedTemplate === '5' || selectedTemplate === '10')
  ) {
    const templateValues = get(formMethods.getValues(), 'versions[0].templateValues');
    if (checkForEmptyData(templateValues?.includeNonFoodProductCodes)) {
      formMethods.setValue('versions[0].templateValues.includeNonFoodProductCodes', undefined);
    }
  }

  const versionTermPath = 'versions.0.term';
  const [term] = formMethods.watch([versionTermPath]);

  const handleNewTerm = (termId: number) => {
    const {
      term_content: termsTranslations,
      term_name: name,
      templateType: template,
      versions,
    } = formMethods.getValues();

    if (termId) {
      const tc = termsConditions.find((t: TermConditionProps) => Number(t.id) === Number(termId));
      const termContent = formMethods.getValues('term_content');

      let dirtyTc = formMethods.formState.dirtyFields.term_content;
      if (tc) {
        Object.keys(tc.translationsMap).forEach((l) => {
          if (tc.translationsMap[l].content !== termContent[l]) {
            dirtyTc = true;
          }
        });
      } else if (
        !terms?.termsConditionsSelection?.termsConditions
          ?.find((t: TermConditionProps) => Number(t.id) === Number(termId))
          ?.template.includes(template)
      ) {
        const persistentTc: any = persistentOffer?.versions?.[0]?.term;

        if (persistentTc && persistentTc.translationsMap) {
          Object.keys(persistentTc.translationsMap).forEach((l) => {
            if (persistentTc.translationsMap[l].content !== termContent[l]) {
              dirtyTc = true;
            }
          });
        }
        if (!dirtyTc) {
          return { id: persistentTc.id };
        }
      }
      if (dirtyTc) {
        return {
          name,
          template: [template],
          originalTermId: Number(versions[0].term),
          translations: Object.entries(termsTranslations).map((value) => ({
            language: value[0],
            content: value[1],
          })),
        };
      }

      return { id: Number(termId) };
    }
  };

  const onSubmit = (isDraft = true) => {
    return async (formData: any) => {
      formData.versions[0].term = handleNewTerm(formData.versions[0].term);

      try {
        if (modalMode === FormMode.New || modalMode === FormMode.Duplicate) {
          setIsSubmitting(true);
          await createNewOffer(formData, isDraft);
          setIsSubmitting(false);
          showToast(MessageType.Success, `Offer added successfully`);
          store.dispatch(closeModal());
        } else if (isInArray([FormMode.Edit, FormMode.View], modalMode)) {
          await onOfferEdit(persistentOffer, formData, isDraft);
        }
      } catch (e) {
        showToast(MessageType.Error, `Failed to ${modalMode === FormMode.Edit ? 'update' : 'create'} offer`);
        setIsSubmitting(false);
      }
    };
  };

  const getOfferImage = (lang: Language) =>
    persistentOffer?.versions && persistentOffer?.versions[0]?.translationsMap[lang]?.image;

  const getModalTitle = () => {
    switch (modalMode) {
      case FormMode.New:
        return 'Add Offer';
      case FormMode.Edit:
        return persistentOffer?.offerSource === OfferSource.VCE
          ? `Edit Offer ID ${persistentOffer?.id}`
          : `Edit DOE Offer ID ${persistentOffer?.id}`;
      case FormMode.View:
        return `View Offer ID ${persistentOffer?.id}`;
      case FormMode.Duplicate:
        return `Duplicate Offer ID ${persistentOffer?.id}`;
      default:
        return '';
    }
  };

  const [isRejectButtonClicked, setIsRejectButtonClicked] = useState(false);

  const handleRejectClick = async (offer1: BaseOffer, rejectionComment: string) => {
    if (!isRejectButtonClicked) {
      setIsRejectButtonClicked(true);
      await onOfferReject(offer1, rejectionComment);
      hideTooltip('#reject-tooltip');
      ReactTooltip.hide();
    }
  };

  const onTagCreation = async (tagName: string) => {
    try {
      const tag = await createOfferTag(tagName);
      return tag?.data?.createTag;
    } catch (e) {
      showToast(
        MessageType.Error,
        `Failed to add tag${
          e.message.includes('duplication item')
            ? ` - ${tagName} already exists in another entity. Please update tag entity or contact Admin`
            : ''
        }
        ${e.message.includes('char limit') ? ` - Exceeds 50 Character limit` : ''}`,
      );
      return null;
    }
  };

  const handleDuplicateChange = () => {
    setTranslations(offer.versions[0].term);
    formMethods.setValue(
      `tags`,
      offer.tags.map((t) => t.id),
    );
  };

  const onActionClicked = async (actionName: OfferActions) => {
    switch (actionName) {
      case OfferActions.Archive:
        await onOfferArchive(offer);
        break;
      case OfferActions.Unarchive: {
        await archiveOffer(offer, false);
        break;
      }
      case OfferActions.Duplicate:
        setModalMode(FormMode.Duplicate);
        handleDuplicateChange();
        break;
      case OfferActions.ViewImpact:
        await onOfferViewImpact(offer);
        break;
      case OfferActions.Preview:
        // TODO: Need to implemeneted
        break;
      default:
    }
  };

  const inputValidation = (value: string, language: string, message: string) => {
    if (
      (language === config.primaryLanguage ||
        (language === config.secondaryLanguage && config.isSecondaryLanguageEnforced)) &&
      !hasValue(value)
    ) {
      return message;
    }
  };

  const shouldShowField = (fieldName: string) => {
    if (modalMode === FormMode.View) {
      const fieldVal = formMethods.getValues(fieldName);
      if (fieldVal && Array.isArray(fieldVal)) {
        return fieldVal?.length > 0;
      }
      return fieldVal;
    }
    return true;
  };

  const onApproveClick = async () => {
    try {
      await setOfferApprovalStatus(
        persistentOffer.versions[0].approvals[0]?.id,
        persistentOffer.id,
        persistentOffer.versions[0].id,
        ApprovalStatus.Approved,
        1,
      );
      showToast(MessageType.Success, `Offer approved successfully`);
      store.dispatch(closeModal());
    } catch (e) {
      if (e.message === inProcessErrorMessage) {
        showToast(
          MessageType.Error,
          `Offer cannot be modified at this time as associated campaign approval is in progress`,
        );
      } else if (e.message === `Image failed to upload in S3 Bucket`) {
        showToast(MessageType.Error, `Offer update failed`);
      } else {
        showToast(MessageType.Error, e.message);
      }
      store.dispatch(closeModal());
    }
  };

  const getTermName = () => {
    const termId = term?.id ?? term;
    const selectedTerm = termsConditions.find((t: any) => Number(t.id) === Number(termId));
    return selectedTerm?.name;
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [formMethods.formState.isDirty, modalMode === FormMode.Duplicate]);

  useEffect(() => {
    if (selectedTemplate && !isInitialMount.current) {
      if (termsConditions?.length === 1) {
        setTranslations(termsConditions[0]);
      } else if (!termsConditions.some((item: { id: any }) => Number(item.id) === Number(term))) {
        // Reset terms
        formMethods.reset(
          {
            ...formMethods.getValues(),
            versions: [{ ...formMethods.getValues('versions.0'), term: undefined }],
            term_content: languages.reduce((langs: any, l: any) => ({ ...langs, [l]: '' }), {}),
            term_name: undefined,
          },
          { keepDirty: true, keepTouched: true },
        );
      }
    } else if (isInitialMount.current) {
      isInitialMount.current = false;

      if (offer?.id && offer?.versions[0].term) {
        setTranslations(offer?.versions[0].term);
      }
    }
  }, [selectedTemplate]);

  useEffect(() => {
    formMethods.reset(formMethods.getValues());
    store.dispatch(setData({ data: { mode: modalMode } }));
  }, [modalMode]);

  useEffect(() => {
    formMethods.setValue(`versions.0.templateValues`, formMethods.getValues(`versions.0.templateValues`), {
      shouldValidate: true,
    });
    loadTags();
    if (termsConditions?.length === 0) {
      store.dispatch(termsConditionsSelection.loadTerms());
    }
  }, []);

  const isValidField = (fieldName: string) => {
    const fieldState = formMethods.getFieldState(fieldName);
    const fieldValue = formMethods.watch(fieldName);
    return fieldValue && !fieldState.error;
  };

  const isValidForPartialDraft = () =>
    Object.keys(formMethods.formState.errors)?.length === 0 &&
    isValidField(`versions.0.translationsMap.${config.primaryLanguage}.title`) &&
    isValidField(`templateType`);
  useEffect(() => {
    if (offerSelectedLanguage) {
      setSelectedLanguage(offerSelectedLanguage);
    }
  }, [offerSelectedLanguage]);

  const resetTermCondition = (selectedTemplate: string) => {
    const termsConditions =
      selectedTemplate && terms?.termsConditionsSelection?.termsConditions
        ? terms.termsConditionsSelection.termsConditions.filter((t: Term) => t.template.includes(selectedTemplate))
        : [];
    if (selectedTemplate && !isInitialMount.current) {
      if (
        termsConditions.length !== 1 &&
        !termsConditions.some((item: { id: any }) => Number(item.id) === Number(term))
      ) {
        formMethods.unregister(versionTermPath);
        formMethods.setValue(versionTermPath, undefined);
      }
    }
  };
  const isTermSelected = term ? true : false;

  const { enableDigitalDownload } = config;
  const filteredOfferTemplates = Object.values(OfferTemplates).filter(
    (template) => enableDigitalDownload || template.id !== '14',
  );

  const getValidationObj = (language: string, value: number, message: string) => {
    const reqValidations: any = {
      required: false,
      maxLength: { value: value, message: message },
    };
    if (selectedTemplate && selectedTemplate === '14') {
      if (
        (config.isSecondaryLanguageEnforced && language === config.secondaryLanguage) ||
        language === config.primaryLanguage ||
        formMethods.getValues(`versions.0.translationsMap.${language}.image`)
      ) {
        reqValidations.required = ValidationMessages.RequiredField;
        reqValidations.validate = (value: string) => inputValidation(value, language, ValidationMessages.RequiredField);
      }
    }
    return reqValidations;
  };

  return (
    <StyledModal
      title={getModalTitle()}
      subtitle={
        modalMode !== FormMode.New && modalMode !== FormMode.Duplicate && <OfferStatusLabel offer={persistentOffer} />
      }
      isLocked={offer?.isLocked}
      {...(offer?.inProgress && { inProgress: true, message: 'Campaign Approval is in progress' })}
      onHover
      ignoreOperations={['VoucherGroups']}
    >
      <FormProvider {...formMethods}>
        <OfferFormContainer>
          <StyledFormSection>
            <StyledSectionTitle>Offer Template</StyledSectionTitle>
            <TemplateSelectbox
              name="templateType"
              control={formMethods.control}
              items={Object.values(filteredOfferTemplates)}
              placeholder="Select"
              label="Offer Template"
              validation={requiredValidation}
              disabled={modalMode === FormMode.View || modalMode === FormMode.Edit}
              onChange={(selected: any) => {
                setSelectedTemplate(selected.id);
                formMethods.unregister('versions.0.templateValues');
                formMethods.setValue('versions.0.templateValues', {});
                resetTermCondition(selected.id);
              }}
            />
            {renderTemplate(selectedTemplate, {
              disabled: modalMode === FormMode.View,
              mode: modalMode,
              offerSource: persistentOffer?.offerSource,
              onProductSelection: useCallback(
                async (
                  valueKey: string,
                  onSave: (data: any) => void,
                  isOptional = false,
                  setType = '',
                  familyGroup = '',
                ) => {
                  const offerProducts = formMethods.getValues(`versions.0.templateValues.${valueKey}`);
                  store.dispatch(
                    openModal({
                      modal: Modals.ProductSetModal,
                      data: { offer: formMethods.getValues(), mode: modalMode },
                      props: {
                        productSet: convertToGenericSet(offerProducts),
                        onSave: (data: SetItemsSelectionFormState) =>
                          onSave({
                            products: Object.values(data.selectedItemsById),
                            productSets: Object.values(data.selectedItemSetsById),
                            excludedProductsIds: Object.keys(data.excludedItemsById).map(Number),
                          }),
                        onCancel: () =>
                          store.dispatch(
                            openModal({
                              modal: Modals.OfferModal,
                              props: { viewOnly, mode: modalMode, offerSource: persistentOffer?.offerSource },
                            }),
                          ),
                        mode: FormMode.Select,
                        setType,
                        familyGroup,
                        isOptional,
                      },
                    }),
                  );
                },
                [modalMode],
              ),
              modalMode: modalMode,
              setVoucherDetails: setVoucherDetails,
            })}
            <StyledError>
              <PriceOffValidationError />
            </StyledError>
          </StyledFormSection>
          <StyledFormSection>
            <StyledSectionTitle>Content</StyledSectionTitle>
            <StyledTabStrip
              tabs={useMemo(
                () =>
                  languages.map((language: Language) => ({
                    id: language,
                    title: LanguageRecord[language],
                  })),
                [],
              )}
              selectedTabId={selectedLanguage}
              onClick={useCallback((tabId: Language) => setSelectedLanguage(tabId), [])}
            />
            <>
              {languages.map((language: Language) => {
                formMethods.register(`versions.0.translationsMap.${language}.language`, { value: language });

                return (
                  <OfferFormContentContainer selected={language === selectedLanguage} key={language}>
                    <OfferFormImageContainer>
                      <OfferFormImage>
                        {getOfferImage(language)?.file ? (
                          <ImageContainer>
                            <img
                              src={getOfferImage(language)?.file}
                              alt={getOfferImage(language)?.name ?? ''}
                              crossOrigin="anonymous"
                            ></img>
                            {modalMode !== FormMode.New ? (
                              <StyledImageButtonText
                                data-automation-id="offer-image-view"
                                onClick={async () => {
                                  store.dispatch(
                                    openModal({
                                      modal:
                                        persistentOffer?.offerSource === OfferSource.VCE
                                          ? Modals.ImageModal
                                          : Modals.DoeImagesModal,
                                      props: {
                                        mode: FormMode.View,
                                        image:
                                          persistentOffer?.offerSource === OfferSource.VCE
                                            ? getOfferImage(language)
                                            : await viewImageClicked(
                                                persistentOffer?.versions[0]?.translationsMap[language]?.image?.id,
                                              ),
                                        viewOnly: true,
                                        onClose: () => {
                                          const editedValues = formMethods.getValues();
                                          editedValues.versions[0].translationsMap.language = {
                                            ...formMethods.getValues().versions[0].translationsMap.language,
                                            ...tempTranslationsMap,
                                          };
                                          store.dispatch(
                                            openModal({
                                              modal: Modals.OfferModal,
                                              props: {
                                                offer: { ...offer, ...editedValues },
                                                defaultLanguage,
                                                mode,
                                                onClose,
                                                viewOnly,
                                                offerFormTags,
                                                offerSelectedLanguage: language,
                                                offerSource: persistentOffer?.offerSource,
                                              },
                                            }),
                                          );
                                        },
                                      },
                                    }),
                                  );
                                }}
                                data-title="View"
                              >
                                <StyledViewImage name="view" />
                              </StyledImageButtonText>
                            ) : (
                              ''
                            )}
                          </ImageContainer>
                        ) : (
                          <NoImageText>No Image Defined</NoImageText>
                        )}
                        <input
                          type="hidden"
                          {...formMethods.register(`versions.0.translationsMap.${language}.image`, {
                            required:
                              (config.isSecondaryLanguageEnforced && language === config.secondaryLanguage) ||
                              language === config.primaryLanguage ||
                              formMethods.getValues(`versions.0.translationsMap.${language}.title`),
                          })}
                        />
                      </OfferFormImage>
                      {modalMode !== FormMode.View && (
                        <StyledButtonText
                          data-automation-id="select-image-button"
                          onClick={() =>
                            store.dispatch(
                              openModal({
                                modal: Modals.ImageSelectionModal,
                                props: { data: { selectedLanguage, offer: formMethods.getValues(), mode: modalMode } },
                                data: { selectedLanguage, offer: formMethods.getValues(), mode: modalMode },
                              }),
                            )
                          }
                        >
                          Select Image*
                        </StyledButtonText>
                      )}
                    </OfferFormImageContainer>
                    <OfferFormContent>
                      <StyledTextField
                        register={formMethods.register}
                        errors={formMethods.formState.errors}
                        name={`versions.0.translationsMap.${language}.title`}
                        value={formMethods.getValues(`versions.0.translationsMap.${language}.title`)}
                        label="Title"
                        onChange={(item: any) => {
                          setTempTranslationsMap((prevState) => ({
                            ...prevState,
                            title: item.target.value,
                          }));
                          formMethods.setValue(`versions.0.translationsMap.${language}.title`, item.target.value, {
                            shouldValidate: true,
                          });
                        }}
                        placeholder="Enter"
                        disabled={modalMode === FormMode.View}
                        validation={{
                          required:
                            (config.isSecondaryLanguageEnforced && language === config.secondaryLanguage) ||
                            language === config.primaryLanguage ||
                            formMethods.getValues(`versions.0.translationsMap.${language}.image`)
                              ? ValidationMessages.RequiredField
                              : false,
                          maxLength: { value: 200, message: 'Up to 200 characters' },
                          validate: (value: string) =>
                            inputValidation(value, language, ValidationMessages.RequiredField),
                        }}
                        labelIsHorizontal
                      />
                      {
                        // TODO: OMS-522 - Hide "POS Title"
                        <StyledTextField
                          register={formMethods.register}
                          errors={formMethods.formState.errors}
                          name={`versions.0.translationsMap.${language}.posTitle`}
                          label="POS Title"
                          placeholder="Enter"
                          onChange={(item: any) => {
                            setTempTranslationsMap((prevState) => ({
                              ...prevState,
                              posTitle: item.target.value,
                            }));
                            formMethods.setValue(`versions.0.translationsMap.${language}.posTitle`, item.target.value, {
                              shouldValidate: true,
                            });
                          }}
                          labelIsHorizontal
                          disabled={modalMode === FormMode.View}
                          validation={{
                            maxLength: { value: 100, message: 'Up to 100 characters' },
                          }}
                        />
                      }
                      {shouldShowField(`versions.0.translationsMap.${language}.subtitle`) && (
                        <StyledTextField
                          register={formMethods.register}
                          errors={formMethods.formState.errors}
                          name={`versions.0.translationsMap.${language}.subtitle`}
                          label="Subtitle"
                          placeholder="Enter"
                          labelIsHorizontal
                          onChange={(item: any) => {
                            setTempTranslationsMap((prevState) => ({
                              ...prevState,
                              subtitle: item.target.value,
                            }));
                            formMethods.setValue(`versions.0.translationsMap.${language}.subtitle`, item.target.value, {
                              shouldValidate: true,
                            });
                          }}
                          disabled={modalMode === FormMode.View}
                          validation={{
                            ...getValidationObj(language, 200, 'Up to 200 characters'),
                          }}
                        />
                      )}
                      {shouldShowField(`versions.0.translationsMap.${language}.description`) && (
                        <StyledTextArea
                          register={formMethods.register}
                          errors={formMethods.formState.errors}
                          name={`versions.0.translationsMap.${language}.description`}
                          label="Description"
                          labelIsHorizontal
                          onChange={(item: any) => {
                            setTempTranslationsMap((prevState) => ({
                              ...prevState,
                              description: item.target.value,
                            }));
                            formMethods.setValue(
                              `versions.0.translationsMap.${language}.description`,
                              item.target.value,
                              {
                                shouldValidate: true,
                              },
                            );
                          }}
                          disabled={modalMode === FormMode.View}
                          placeholder="Enter"
                          validation={{
                            ...getValidationObj(language, 10000, 'Up to 10,000 characters'),
                          }}
                          rows={1}
                        />
                      )}
                      {!offer?.versions[0].term && modalMode === FormMode.View ? (
                        <StyledTextField
                          name="versions.0.term"
                          label="Terms and Conditions"
                          placeholder="No T&C Defined"
                          validation={requiredValidation}
                          disabled
                          labelIsHorizontal
                          value={getTermName()}
                        />
                      ) : (
                        <>
                          {selectedLanguage === language && (
                            <StyledSelectbox
                              key={`${JSON.stringify(termsConditions)}_${selectedTemplate}`}
                              label="Terms and Conditions"
                              validation={requiredValidation}
                              labelIsHorizontal
                              items={termsConditions}
                              placeholder={selectedTemplate ? 'Select' : 'Offer template must be selected to proceed'}
                              name="versions.0.term"
                              control={formMethods.control}
                              disabled={
                                !termsConditions ||
                                modalMode === FormMode.View ||
                                selectedTemplate === undefined ||
                                termsConditions?.length === 0
                              }
                              onChange={(item: any) => setTranslations(item)}
                              errors={
                                termsConditions && termsConditions?.length === 0 && selectedTemplate
                                  ? modalOfferSource === OfferSource.VCE
                                    ? 'Default T&C need to be defined from Settings → Terms & Conditions'
                                    : modalOfferSource === OfferSource.DOE
                                    ? 'Default T&C need to be defined from Configurations'
                                    : formMethods.formState.errors
                                  : formMethods.formState.errors
                              }
                              defaultValue={term}
                              initialSelectedItems={term ? [typeof term === 'object' ? term.id : term] : []}
                            />
                          )}

                          <StyledTermsAndConditionsTextArea>
                            {shouldDisplayInfoIcon && (
                              <StyledInfoTooltip content="Changes made to the T&C from within the offer will not be reflected in the T&C repository. In addition, no future template changes will be applied to this T&C." />
                            )}
                            <StyledTextArea
                              register={formMethods.register}
                              errors={formMethods.formState.errors}
                              name={`term_content.${language}`}
                              rows={3}
                              disabled={
                                !termsConditions ||
                                !termsConditions?.length ||
                                modalMode === FormMode.View ||
                                (persistentOffer?.offerSource === OfferSource.DOE && modalMode === FormMode.Edit) ||
                                formMethods.watch(versionTermPath) === undefined
                              }
                              validation={{
                                maxLength: { value: 100e3, message: 'Up to 100k characters' },
                                validate: (value: string) =>
                                  inputValidation(value, language, ValidationMessages.RequiredField),
                              }}
                            />
                          </StyledTermsAndConditionsTextArea>
                        </>
                      )}
                    </OfferFormContent>
                  </OfferFormContentContainer>
                );
              })}
            </>
            <OfferFormAdditionalDetails>
              <TagsSelectbox
                key={`${Boolean(tags.length)}`}
                multiple
                label="Tags"
                placeholder="Select"
                control={formMethods.control}
                errors={formMethods.formState.errors}
                name="tags"
                enableTagHover={modalMode === FormMode.View ? true : false}
                items={tags}
                disabled={false}
                reset
                onCreateOption={(tagName: string) => onTagCreation(tagName)}
                withSearch
                labelIsHorizontal
                withAmount
                limit={10}
                maxItems={1}
              />
              {selectedTemplate !== '14' && (
                <CheckboxGroup
                  label="Point of Distribution"
                  control={formMethods.control}
                  name="versions.0.pointOfDistribution"
                  disabled={modalMode === FormMode.View}
                  labelIsHorizontal
                  checkboxes={convertEnumToObjectArray(PointOfDistributionRecord, 'id', 'label') as any[]}
                  defaultValue={[PointOfDistributionRecord.delivery, PointOfDistributionRecord.pickup]}
                  errors={formMethods.formState.errors}
                  validation={{
                    required: 'Must select at least 1 Point of Distribution',
                  }}
                />
              )}
              <FeatureGuard features={[{ feature: Feature.SegmentId }]}>
                <StyledTextField
                  register={formMethods.register}
                  errors={formMethods.formState.errors}
                  name="segmentId"
                  label="Group ID"
                  placeholder="Enter"
                  labelIsHorizontal
                  disabled={disabledGroupIdInput}
                />
              </FeatureGuard>
            </OfferFormAdditionalDetails>
          </StyledFormSection>
        </OfferFormContainer>
      </FormProvider>
      <OfferFormFooter>
        <ButtonText
          data-for="cancel-tooltip"
          {...((formMethods.formState.isDirty || modalMode === FormMode.Duplicate) && modalMode !== FormMode.View
            ? { 'data-tip': true, onClick: () => null }
            : { onClick: () => (!onClose ? store.dispatch(closeModal()) : onClose()) })}
        >
          {modalMode !== FormMode.View ? 'Cancel' : 'Close'}
        </ButtonText>
        {modalMode !== FormMode.View ? (
          <>
            {modalMode === FormMode.Edit && persistentOffer?.offerSource === OfferSource.DOE ? (
              <ButtonContained
                onClick={formMethods.handleSubmit(onSubmit(false))}
                disabled={
                  !formMethods.formState.isValid ||
                  isSubmitting ||
                  !hasProductsOrVoucherGroup ||
                  termsConditions?.length === 0
                }
              >
                Save
              </ButtonContained>
            ) : (
              <>
                <ButtonContained
                  onClick={formMethods.handleSubmit(onSubmit(false))}
                  disabled={
                    !formMethods.formState.isValid ||
                    isSubmitting ||
                    !hasProductsOrVoucherGroup ||
                    hasExpireVoucherGroupError
                  }
                >
                  Save & Submit for Approval
                </ButtonContained>
                <ButtonContained
                  onClick={
                    draftCampaignOfferEnhancement
                      ? () => onSubmit()(formMethods.getValues())
                      : formMethods.handleSubmit(onSubmit())
                  }
                  disabled={
                    (draftCampaignOfferEnhancement ? !isValidForPartialDraft() : !formMethods.formState.isValid) ||
                    isSubmitting
                  }
                >
                  Save as Draft
                </ButtonContained>
              </>
            )}
          </>
        ) : (
          <>
            {!offer?.isLocked && !viewOnly && (
              <>
                <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                  {offer?.isArchive ? (
                    <ButtonContained
                      onClick={async () => {
                        await archiveOffer(offer, false);
                      }}
                    >
                      Unarchive
                    </ButtonContained>
                  ) : (
                    <>
                      {persistentOffer?.offerSource === OfferSource.DOE ? (
                        <StyledButtonDropdown
                          items={[{ id: OfferActions.ViewImpact, name: OfferActions.ViewImpact }] as any[]}
                          onItemClick={(item: any) => onActionClicked(item.name)}
                        >
                          More Actions
                        </StyledButtonDropdown>
                      ) : (
                        <StyledButtonDropdown
                          items={Object.values(OfferActionsList) as any[]}
                          onItemClick={(item: any) => onActionClicked(item.name)}
                        >
                          More Actions
                        </StyledButtonDropdown>
                      )}
                      {persistentOffer?.offerSource === OfferSource.VCE &&
                        (offerStatus === ApprovalStatus.Draft || offerStatus === ApprovalStatus.Rejected) && (
                          <ButtonContained
                            onClick={formMethods.handleSubmit(onSubmit(false))}
                            disabled={
                              !formMethods.formState.isValid ||
                              isSubmitting ||
                              !hasProductsOrVoucherGroup ||
                              !isTermSelected ||
                              hasExpireVoucherGroupError
                            }
                          >
                            Submit for Approval
                          </ButtonContained>
                        )}
                      {persistentOffer?.offerSource === OfferSource.DOE ? (
                        <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin]}>
                          <ButtonContained onClick={() => setModalMode(FormMode.Edit)}>Edit</ButtonContained>
                        </RoleGuard>
                      ) : (
                        <ButtonContained onClick={() => setModalMode(FormMode.Edit)}>Edit</ButtonContained>
                      )}
                    </>
                  )}
                  {persistentOffer?.offerSource === OfferSource.VCE &&
                    (offerStatus === ApprovalStatus.PendingApproval || offerStatus === ApprovalStatus.Approved) && (
                      <EntityApproveButton entity={persistentOffer.versions[0]} entityType={EntityType.Offer}>
                        <StyledRejectButton onClick={() => null} data-tip data-for="reject-tooltip">
                          Reject
                        </StyledRejectButton>
                      </EntityApproveButton>
                    )}
                  {persistentOffer?.offerSource === OfferSource.VCE &&
                    offerStatus === ApprovalStatus.PendingApproval && (
                      <EntityApproveButton entity={persistentOffer.versions[0]} entityType={EntityType.Offer}>
                        <StyledApproveButton onClick={onApproveClick} disabled={hasExpireVoucherGroupError}>
                          Approve
                        </StyledApproveButton>
                      </EntityApproveButton>
                    )}
                </RoleGuard>
                <RoleGuard roles={[UserRole.Viewer]}>
                  <StyledButtonDropdown
                    items={[{ id: OfferActions.ViewImpact, name: OfferActions.ViewImpact }] as any[]}
                    onItemClick={(item: any) => onActionClicked(item.name)}
                  >
                    More Actions
                  </StyledButtonDropdown>
                </RoleGuard>
              </>
            )}
          </>
        )}
        <Tooltip
          id="cancel-tooltip"
          content="Are you sure you want to cancel?"
          onDisapproveClick={() => {
            hideTooltip('#cancel-tooltip');
          }}
          onApproveClick={() => store.dispatch(closeModal())}
        />
        <Tooltip
          id="reject-tooltip"
          content="Are you sure you want to reject?"
          onDisapproveClick={() => {
            hideTooltip('#reject-tooltip');
            ReactTooltip.hide();
            setIsRejectButtonClicked(false);
          }}
          eventOff={null}
          approveMsg="Yes, Reject"
          isWithResponse
          responsePlaceholder="Enter reject reason"
          onApproveWithResponseClick={(rejectionComment) => {
            handleRejectClick(offer, rejectionComment);
          }}
        />
      </OfferFormFooter>
    </StyledModal>
  );
};

export default OfferForm;
